<template>
  <div class="trace-back-wrapper">
    <div class="full-with-banner">
      <Nav nums='3' isColor='#fff'/>
      <div class="homebanner">
           <Video :bannerArr='coverBanner'/>
         </div>
    </div>
    <div class="trak-one-wrapper">
      <div class="restrict-content">
        <SectionTitle title="trakone"/>
        <SectionSubTitle title="消毒供应中心可追溯管理信息系统"/>
      </div>
      <div class="trak-one-inner-wrapper">
        <div class="linear-gradient-background" />
        <div class="restrict-content">
          <div class="trak-one-content-wrapper">
            <div class="text-content-wrapper">
              <div class="title">全球独创的区域化消毒供应监管系统</div>
              <div class="name">TrakOne</div>
              <div class="brief-desc">为消毒供应监管业务应用正常运行提供有力的支撑，提高监管效率与质量。</div>
              <div class="cert">通过了工业和信息化部的工信部云计算服务能力评估，可为政府提供监管平台服务，同时也可为医疗机构提供在线服务</div>
              <div class="functions-title">区域化消毒中心追溯系统的四大功能</div>
              <div class="functions-wrapper">
                <div class="function">质量追溯管理</div>
                <div class="function">财务数据管理</div>
                <div class="function">管理绩效评估</div>
                <div class="function">运行管理保障</div>
              </div>
            </div>
            <div class="wheel-wrapper">
              <img class="main-image" src="../../assets/trace-back/wheel.png" alt="trakone">
              <img class="arrow" src="../../assets/trace-back/arrow.png" alt="trakone-arrow">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-advantages-wrapper">
      <div class="restrict-content">
        <SectionTitle title="product" :with-highlighted-tail="false" :with-dot="false"/>
        <SectionTitle title="advantages"/>
        <SectionSubTitle title="产品优势"/>
      </div>
      <div class="advantage-list">
        <div class="linear-gradient-background" />
        <div class="advantage-wrapper" v-for="(item,index) in advantageArr" :key="index">{{item.content}}</div>
      </div>
    </div>

    <div class="restrict-content available-services-wrapper">
      <SectionTitle title="configuration" :with-highlighted-tail="false" :with-dot="false"/>
      <SectionTitle title="selection"/>
      <SectionSubTitle title="配置选择"/>
      <div class="available-services">
        <div class="available-service" v-for="(item,index) in selectionArr" :key="index">
          <div class="icon"><img :src="item.images" alt="追溯系统"/></div>
          <div class="text-content">
            <div class="name">{{item.title}}</div>
            <div class="desc">{{item.content}}</div>
          </div>
        </div>
      </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import NavMixin from '@/mixins/nav-minxin'
  import { imgHttp, request } from '../../apis/http'
  import Video from '../../components/video.vue'

export default {
  mixins: [NavMixin],
  components:{
    Video
  },
  data() {
    return {
      coverBanner:[],
      bannerImg:[],
      advantageArr:[],
      selectionArr:[]
    }
  },
  beforeMount(){
    scrollTo(0, 0)
    this.bigBanner()
    this.advantage()
    this.Selection()
  },
  name: 'TraceBack',
    // 大banner
  methods:{
    async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=2'
      })
       const bannerNewArr = res.data.data.map(i => {
        if (i.images !== ''){
            i.images = i.images.split(',').map(r => {
                r = imgHttp + r
                return r
            })
          }
          if (i.videos !== ''){
              i.videos = i.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          i.arr = [...i.videos, ...i.images]
        return i
      })
        this.coverBanner = bannerNewArr[0].arr
   },
    // 产品优势
    async advantage () {
      const res = await request({
        url: '/api/index/course?type=5'
      })
      this.advantageArr = res.data.data
   },
   // 配置选择
    async Selection () {
      const res = await request({
        url: '/api/index/course?type=6'
      })
      const newArr = res.data.data.map(i => {
        i.images = imgHttp + i.images
        return i
      })
      this.selectionArr = newArr
   }
  }
}
</script>

<style lang="less" scoped>
  @import '../fn.less';
@keyframes trace-back-service-shake {
  0% {
    transform: rotateZ(-30deg);
  }
  50% {
    transform: rotateZ(30deg);
  }
  100% {
    transform: rotateZ(-30deg);
  }
}

@keyframes trakone-roll {
  0% {
    transform: translate(calc(-50% - 4.46875vw), calc(-50% + 0.7558333vw)) rotateZ(-1deg);
  }
  25% {
    transform: translate(calc(-50% - 2.3729166vw), calc(-50% + -5.0979166vw)) rotateZ(90deg);
  }
  50% {
    transform: translate(calc(-50% - -3.575vw), calc(-50% + -3.1104166vw)) rotateZ(178.5deg);
  }
  75% {
    transform: translate(calc(-50% - -1.583333vw), calc(-50% + 2.8375vw)) rotateZ(269deg);
  }
}
.trace-back-wrapper {
  @import "../../style/common.less";
  .full-with-banner{
    width: 100%;
    height: 46vw;
    position: relative;
    .homebanner{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
  .linear-gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(132, 197, 243, 0.13), rgba(0, 0, 0, 0));
  }

  .trak-one-wrapper {
    margin-top: 11.041666vw;
    .trak-one-inner-wrapper {
      position: relative;
      .trak-one-content-wrapper {
        margin-top: 3.729166vw;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .text-content-wrapper {
          max-width: 26.041666vw;
          color: #6C7388;
          padding: 2.2041666vw 0;
          .title {
            padding: 0!important;
            font-size: 1.5625vw;
            font-weight: 400;
          }
          .name {
            font-size: 2.66875vw;
            font-weight: 800;
            color: #00538D;
            letter-spacing: 2px;
            text-align: right;
            margin-top: 0.220833vw;
          }
          .brief-desc {
            font-size: 1.20209333vw;
            font-weight: 400;
            line-height: 2.0875vw;
          }
          .cert {
            font-size: 1.20209333vw;
            font-weight: 400;
            margin-top: 0.2604166vw;
            line-height: 1.78416vw;
          }
          .functions-title {
            margin-top: 1.0625vw;
            font-size: 1.5202vw;
            font-weight: 400;
            color:#00538D;
          }
          .functions-wrapper {
            margin-top: 1.52291667vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            color:#00538D;
            .function {
              &::before {
                content: "";
                width: 0.36459333vw;
                height: 0.36459333vw;
                background: #00538D;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.520833vw;
              }
              font-size: 1.5202vw;
              // font-family: Alibaba PuHuiTi,serif;
              font-weight: 400;
              line-height: 2.5202vw;
              margin-right: 3.38541666vw;
            }
          }
        }
        .wheel-wrapper {
          position: relative;
          width: 47.2395833vw;
          img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 60%;
            transform: translate(-50%, -50%);
          }
          .main-image {
            width: 38.2395833vw;
            height: 36.41666vw;
          }
          .arrow {
            transform: translate(calc(-50% - 4.46875vw), calc(-50% + 0.1458333vw)) rotateZ(-1deg);
            animation-name: trakone-roll;
            animation-timing-function: steps(1, end);
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            width: 6.3333vw;
          }
        }
      }
    }
  }

  .product-advantages-wrapper {
    margin-top: 10.4479167vw;
    .advantage-list {
      margin: 2.02vw auto 0;
      position: relative;
      max-width: 62.5vw;
      padding: 2.6041666vw 0 3.125vw;
      box-sizing: border-box;
      .linear-gradient-background {
        // width: 100vw;
        // left: calc((980px - 100vw) / 2);
      }
      .advantage-wrapper {
        font-size: 0.8375vw;
        font-weight: 400;
        color: #101218;
        line-height: 1.1666vw;
        position: relative;
        padding-left: 1.04166vw;
        transition: .25s;
        cursor: default;
        box-sizing: border-box;
        &:hover {
          font-size: 0.9458333vw;
          color: #00538D;
        }
        &:not(:last-child) {
          margin-bottom: 1.5625vw;
        }
        &::before {
          position: absolute;
          top: 0.4620833vw;
          left: 0;
          content: '';
          width: 0.416666vw;
          height: 0.416666vw;
          background: #00538D;
          border-radius: 50%;
        }
      }
    }
  }

  .available-services-wrapper {
    margin-top: 10.6666vw;
    margin-bottom: 5vw;
    .available-services {
      margin-top: 3.6458333vw;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      // max-width: 1200px;
      .available-service {
        max-width: 25vw;
        margin: 0 0.625vw 2.083333vw;
        display: flex;
        transition: .25s;
        cursor: default;
        padding: 1.04166vw;
        border-radius: 1.04166vw;
        .icon {
          width: 4.0104166vw;
          margin-right: 1.5625vw;
          img {
            width: 100%;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
          }
        }
        .text-content {
          flex: 1 0;
          .name {
            font-size: 1.04166vw;
            font-weight: bold;
            color: #101218;
            line-height: 1.66666vw;
          }
          .desc {
            white-space: pre-wrap;
            font-size: 1.04166vw;
            // font-family: Alibaba PuHuiTi,serif;
            font-weight: 400;
            color: #6C7388;
            line-height: 1.66666vw;
          }
        }
        &:hover {
          transform: scale(1.2);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
          .icon {
            img {
              transform: rotateZ(-30deg);
              transition: .25s;
              animation-delay: .25s;
              animation-name: trace-back-service-shake;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  @keyframes trakone-roll {
  0% {
    transform: translate(calc(-50% - 9.46875vw), calc(-50% + 1.9558333vw)) rotateZ(-1deg);
  }
  25% {
    transform: translate(calc(-50% - 5.2729166vw), calc(-50% + -11.2979166vw)) rotateZ(90deg);
  }
  50% {
    transform: translate(calc(-50% - -7.975vw), calc(-50% + -7.0104166vw)) rotateZ(178.5deg);
  }
  75% {
    transform: translate(calc(-50% - -3.583333vw), calc(-50% + 5.8375vw)) rotateZ(269deg);
  }
}
.full-with-banner{
    .homebanner{
      margin-top: 5vw;
    }
  }
.trace-back-wrapper {
  .trak-one-wrapper {
    margin-top: 11.041666vw;
    .trak-one-inner-wrapper {
      position: relative;
      .trak-one-content-wrapper {
        margin-top: 0;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        .text-content-wrapper {
          max-width: 100%;
          .title{
            .vvw(font-size,24);
          }
          .brief-desc {
            line-height: 6.0875vw;
            .vvw(font-size,24);
          }
          .name {
            .vvw(font-size,68);
            text-align: left;
            margin-top: 2.220833vw;
          }
          .cert {
            .vvw(font-size,24);
            margin-top: 2.220833vw;
            line-height: 5.78416vw;
          }
          .functions-title{
            .vvw(font-size,24);
          }
          .functions-wrapper {
            .vvw(font-size,24);
            justify-content: center;
            .function {
            .vvw(font-size,24);
              line-height: 6.5202vw;
              margin-right:16.38541666vw;
            }
          }
        }
        .wheel-wrapper {
          height: 90vw;
          img {
            left: 50%;
          }
          .main-image {
            width: 80.2395833vw;
            height: 80.41666vw;
          }
          .arrow {
            transform: translate(calc(-50% - 4.46875vw), calc(-50% + 0.1458333vw)) rotateZ(-1deg);
            animation-name: trakone-roll;
            animation-timing-function: steps(1, end);
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            width: 14.3333vw;
          }
        }
      }
    }
  }
  .product-advantages-wrapper {
    margin-top: 10.4479167vw;
    .advantage-list {
      margin: 2.02vw 5vw 0;
      position: relative;
      max-width: 100%;
      padding: 2.6041666vw 0 3.125vw;
      box-sizing: border-box;
      .linear-gradient-background {
        // left: calc((980px - 100vw) / 2);
      }
      .advantage-wrapper {
      .vvw(font-size,24);
      .vvw(line-height,40);
        margin-top: 3vw;
        &::before {
          background: #fff;
        }
        &:hover {
         .vvw(font-size,24);
          color: #00538D;
        }
      }
    }
   }
   .available-services-wrapper {
    margin-top: 10.6666vw;
    margin-bottom: 5vw;
    .available-services {
      margin-top: 3.6458333vw;
      flex-direction: column;
      .available-service {
        max-width: 100%;
        margin: 0 0.625vw 2.083333vw;
        .icon {
          width: 12.0104166vw;
          height: 10.0104166vw;
          margin-right: 3.5625vw;
          img {
            width: 100%;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
          }
        }
        .text-content {
          flex: 1 0;
          .name {
           .vvw(font-size,24);
            font-weight: bold;
            color: #101218;
            .vvw(line-height,30);
          }
          .desc {
            .vvw(font-size,24);
            .vvw(line-height,30);
            margin-top: 2vw;
          }
        }
        &:hover {
          transform: scale(1.02);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
          .icon {
            img {
              transform: rotateZ(-30deg);
              transition: .25s;
              animation-delay: .25s;
              animation-name: trace-back-service-shake;
            }
          }
        }
      }
    }
  }
  }
}
</style>
